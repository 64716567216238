import React from 'react'
import{
createBrowserRouter,
RouterProvider,
}from 'react-router-dom'
import {Home} from './Pages/Home/Home'
import {Product} from './Pages/Product'
import {Quality} from './Pages/Quality'
import {Contact} from './Pages/Contact'
import { About } from './Pages/About'




export const App = () => {
  const router = createBrowserRouter([
    {
      path:"/",
      element:<Home />,

    },
    {
      path:"/about",
      element:<About />,
    },
    {
      path:"/product",
      element:<Product />,
    },
    {
      path:"/quality",
      element:<Quality />,
    },
    {
      path:"/contact",
      element:<Contact />
    }
  ])
  return (
    <div>
    <RouterProvider router={router} />
    </div>
  )
}
export default App;
