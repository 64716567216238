import React from "react";
import { Card2 } from "../../Components/Card2";
import Homeicon1 from "../../Assert/Home/icon-2.png";
import Homeicon2 from "../../Assert/Home/icon.png";
import Homeicon3 from "../../Assert/Home/icon-3.png";
import Homeicon4 from "../../Assert/Home/icon-4.png";
import Homeicon5 from "../../Assert/Home/home-page-img.png";
export const Section5 = () => {
  return (
    <div>


      <div className="section-5">
      <h2 className="section-5-title">Every Bread We Bake</h2>

        <div className="container">
          <div className="row">
            <div className="col-md-5 home-bread-img1">
               <img className="home-bread-img" data-aos="zoom-in"  data-aos-duration="1000" src={Homeicon5}/>
            </div>

            <div className="col-md-7">
                <div className="row">
                <div className="col-md-6">
                <Card2  icon={Homeicon1} title= {"Premium Ingredients"} dec = {"Our team of expert chefs and food connoisseurs have traveled the globe to handpick the highest quality ingredients from renowned sources. From rare spices and herbs to succulent meats and organic produce, we leave no stone unturned in our quest for perfection."}/>
                </div>
                <div className="col-md-6">
                <Card2  icon={Homeicon2} title ={"Artisanal Craftsmanship"} dec ={"we have poured our passion and expertise into creating a collection of culinary marvels that exemplify the true essence of artisanal craftsmanship. Every step of the journey, from sourcing the finest ingredients to the meticulous preparation, is a labor of love that culminates in an exquisite and unparalleled dining experience."}/>
                </div>
                <div className="col-md-6">
                <Card2  icon={Homeicon3} title ={"Freshness"} dec ={"We believe in serving food that not only delights your taste buds but also nourishes your body. Our fruits and vegetables are sourced from trusted farms, ensuring that you savor the natural goodness and nutritional benefits of each bite."}/>
                </div>
                <div className="col-md-6">
                <Card2  icon={Homeicon4} title ={"Innovation in products"}dec ={"Drawing inspiration from age-old traditions and time-honored techniques, our artisans use their expertise to coax out the best from the ingredients. From slow-roasting and hand-grinding to fermenting and curing, these traditional methods are what set our products apart."}/>

                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
