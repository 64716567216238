import React,{useState} from 'react'
import ProductModal from "../Components/ProductModal";
import '../Css/Product.css'

const ProductCard = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => {
      setModalShow(false);
    };
  return (
    <div className="col-md-3">
    <div className='product-image snip1205 green'>

        <i class="ion-search"></i>
        <a href="#"></a>
          <img src={props.img} alt="" onClick={() => setModalShow(true)} />
          <h3>{props.title}</h3>
          </div>
          <ProductModal show={modalShow} onHide={handleClose} img={props.img} />
    </div>
  )
}

export default ProductCard
