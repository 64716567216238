import React, { useState } from "react";
import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";
import Product01 from "../Assert/ProductSource/01.png";
import Product02 from "../Assert/ProductSource/02.png";
import Product03 from "../Assert/ProductSource/03.png";
import Product04 from "../Assert/ProductSource/04.png";
import Product05 from "../Assert/ProductSource/05.png";
import Product06 from "../Assert/ProductSource/06.png";
import Product07 from "../Assert/ProductSource/07.png";
import Product08 from "../Assert/ProductSource/08.png";
import Product09 from "../Assert/ProductSource/09.png";
import Product10 from "../Assert/ProductSource/10.png";
import Product11 from "../Assert/ProductSource/11.png";
import Product12 from "../Assert/ProductSource/12.png";
import Product13 from "../Assert/ProductSource/13.png";
import Product14 from "../Assert/ProductSource/14.png";
import Product15 from "../Assert/ProductSource/15.png";
import ProductCard from "../Components/ProductCard";

export const Product = () => {

  return (
    <div>
      <Navbar />
      <div className="banner">
        <div className="banner-image">
          <div className="container">
            <h3>Product</h3>
          </div>
        </div>
      </div>
      <div className="abt-sec">
          <p>
          Each product in our Artisanal Craftsmanship range is meticulously handcrafted by skilled artisans who have honed their craft over generations. We believe that the human touch is essential to bring out the unique flavors and textures that machines simply cannot replicate.
          </p>
        </div>
      <div className="ProductSection">
      <div className="container">
        <div className="row">

           <ProductCard img={Product01} title={"Family Bread"}/>
           {/* <ProductCard img={Product02}/> */}
           <ProductCard img={Product03} title={"Cream Bread"}/>
           <ProductCard img={Product04}  title={"Cream Bread"}/>
           <ProductCard img={Product05}  title={"Coconut Bun"}/>
           <ProductCard img={Product06}  title={"Cream Cake"}/>
           <ProductCard img={Product07}  title={"Mini Bread"}/>
           <ProductCard img={Product08}  title={"Sweet Bread"}/>
           <ProductCard img={Product09}  title={"Plum Bread"}/>
           <ProductCard img={Product10}  title={"Cream Cake"}/>
           <ProductCard img={Product11}  title={"Roasted Bread"}/>
           <ProductCard img={Product12}  title={"Chilli Snacks"}/>
           <ProductCard img={Product13}  title={"Chilli Snacks"}/>
           <ProductCard img={Product14}  title={"Chilli Snacks"}/>
           <ProductCard img={Product15}  title={"Rice Murukku"}/>

        </div>
      </div>
      </div>


      <Footer />
    </div>
  );
};
