import { Navbar } from "../../Components/Navbar";
import Icon from "../../Assert/Home/tasty-icon.png";
import Icon2 from "../../Assert/Home/recipe.png";
import Icon3 from "../../Assert/Home/pastries.png";
import "../../Css/Home.css";
import 'animate.css';

import { Card } from "../../Components/Card";
import { Slider } from "../Layouts/Slider";
import { Section3 } from "../Layouts/Section3";
import { Section4 } from "../Layouts/Section4";
import { Section5 } from "../Layouts/Section5";
import { Footer } from "../../Components/Footer";
export const Home = () => {
  return (
    <div>
      <Navbar />
<Slider />

      <div className="container">
        <div className="row animate__fadeInDown">
        <Card Icon={Icon} title={"Homemade Breads"} description={"Experience the rustic charm and authentic flavours of our artisan bread collection. From crusty baguettes and hearty whole wheat loaves"}/>

          <Card Icon={Icon2} title={"Decadent Cakes"} description={"Celebrate life's special moments with our decadent cakes. Whether you're commemorating a birthday, wedding, or any other joyous occasion"}/>

          <Card Icon={Icon3} title={"Cookies and Confections"} description={" Treat yourself to our delectable assortment of cookies and confections. From melt-in-your-mouth butter cookies to chewy chocolate chip delights"}/>

        </div>
      </div>
      <Section3 />
      <Section4 />
      <Section5 />
<Footer />
    </div>
  );
};
