import React from "react";
import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";
import About1 from "../Assert/AboutSource/abt1.jpg";
import About2 from "../Assert/AboutSource/abt2.jpg";
import About3 from "../Assert/AboutSource/abt3.jpg";
import About4 from "../Assert/AboutSource/abt4.jpg";
import About5 from "../Assert/AboutSource/abt5.jpg";
import "../Css/About.css";


export const About = () => {
  return (
    <div>
      <Navbar />
      <div className="banner">
        <div className="banner-image">
          <div className="container">
            <h3>About Us</h3>
          </div>
        </div>
      </div>
      <div className="abt-sec">
          <p>
            Indulge in the aroma of freshly baked bread and the allure of
            delectable pastries as you step into the world of vel breads. As a
            premier bread and pastries manufacturing company, we take pride in
            bringing the joy of artisanal baking to every home.
          </p>
        </div>
      <div className="container">

        <div className="row abt-sec-5">
          <div className="col-md-6">
            <div className="abt-sec1">
              <h3>About Our Company </h3>
              <p>
                Vel Breads was founded in 1999, and we are passionate about the
                art of baking. The company started out small, but it quickly
                grew in popularity. With decades of experience and a team of
                dedicated bakers, we have mastered the craft of creating
                exceptional bread and pastries. We are committed to producing
                high-quality, fresh-baked goods. We use only the finest
                ingredients and bake our products using traditional methods. Our
                innovation has made us a trusted name in the industry, and we
                strive to deliver delicious treats that exceed your
                expectations.
              </p>
              <p>
                Prepare to tantalize your taste buds with our wide range of
                bread and pastries. From crusty artisan bread to delicate French
                pastries, every item in our collection is a result of meticulous
                craftsmanship and the finest ingredients. Indulge in our freshly
                baked baguettes, croissants, muffins, cakes, cookies, and more,
                each crafted with love and precision.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="abt-sec-2">
              <img className="inner-img" src={About1} alt="" />
            </div>
          </div>
          <div className="row abt-sec-4">
          <div className="col-md-3">
            <div className="abt-sec-3">
            <img src={About2} alt="" />
            </div>
          </div>
          <div className="col-md-3">
          <div className="abt-sec-3">
          <img src={About3} alt="" />
          </div>
          </div>
          <div className="col-md-3">
          <div className="abt-sec-3">
          <img src={About4} alt="" />
          </div>
          </div>
          <div className="col-md-3">
          <div className="abt-sec-3">
          <img src={About5} alt="" />
          </div>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
