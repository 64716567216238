import React from 'react'
// import icon from '../Assert/Home/icon.png'

export const Card2 = (props) => {
  return (
    <div>

        <div className='Home_premium' data-aos="fade-up"  data-aos-duration="1000">
       <div className='home-bread-title'
>
       <img  src={props.icon} />
        <h3>{props.title}</h3>
       </div>
        <p>{props.dec}</p>
        </div>
    </div>
  )
}
