import React from 'react'
import "../Css/Home.css";

export const Card = (props) => {

  return (
    <div className="col-md-4 home-sec-icon" data-aos="fade-down"  data-aos-duration="1000">
            <div class="iconbox-top md-align-center">
              <div class="iconbox-top-content">
                <div class="hover-holder">
                  <div class="icon-holder">
                    <svg class="svg-circle">
                      <circle
                        cx="49"
                        cy="49"
                        r="47"
                        stroke="rgb(0,0,0)"
                        stroke-width="2"
                        fill="none"
                      >
                        {" "}
                      </circle>
                    </svg>
                    <div class="icon-1">
                      {" "}
                      <img src={props.Icon} alt='icon'/>
                    </div>
                  </div>
                  <h1 class="title-1">{props.title}</h1>
                </div>
                <p class="description">
                  {props.description}
                </p>
              </div>
            </div>
          </div>
  )
}

