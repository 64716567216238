import React from "react";
// import Sectionimg from "../../Assert/Home/home-bun.png";
// import Sectionimg1 from "../../Assert/Home/jam.png";
// import Sectionimg2 from "../../Assert/Home/multigrain.png";
// import Sectionimg3 from "../../Assert/Home/milk.png";
// import Sectionimg4 from "../../Assert/Home/rusk.png";
import Sectionimg5 from "../../Assert/Home/shop-1.jpg";
import Sectionimg4 from "../../Assert/Home/shop-2.jpg";
import Sectionimg3 from "../../Assert/Home/shop-3.jpg";
import Sectionimg6 from "../../Assert/Home/shop-4.jpg";
import Sectionimg7 from "../../Assert/Home/shop-5.jpg";
import Sectionimg2 from "../../Assert/Home/shop-7.jpg";
import Sectionimg0 from "../../Assert/Home/masonry-gallery-4.jpg";

// Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export const Section4 = () => {
  return (
    <div className="home-section-4">
      <div className="home-product">
        <div className="container">
          <div className="row">
            <div className="col-md-8 home-product-2">
              <div className="home-product-container-1" data-aos="zoom-in"  data-aos-duration="1000">
                <h2>OUR SHOP</h2>
                <h3>All Products</h3>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                    We prioritize exceptional customer service. Our knowledgeable and friendly staff are always ready to assist with your order.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                    We strive to make your experience with us seamless and enjoyable from start to finish.
                    </p>
                  </div>
                </div>
                {/* <button>CATALOGUE</button> */}
              </div>
              <div className="row home-img-product" data-aos="fade-up" data-aos-duration="1000">
                <Swiper
                  modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                  spaceBetween={30}
                  slidesPerView={4}
                  autoplay={true}
                  loop={true}
                  navigation
                  // pagination={{ clickable: true }}
                  Autoplay={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                >
                  <SwiperSlide>
                    <img className="product-img-home"  alt="" src={Sectionimg5} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="product-img-home" alt="" src={Sectionimg4} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="product-img-home" alt="" src={Sectionimg3} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="product-img-home" alt="" src={Sectionimg2} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="product-img-home" alt="" src={Sectionimg6} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="product-img-home" alt="" src={Sectionimg7} />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="col-md-4">
              <img className="product-img-home mobile-change-home" data-aos="zoom-in"  data-aos-duration="1000" src={Sectionimg0} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
