import React from "react";
import "./Footer.css";
import Foologo from "../Assert/Logo/logo.png"
import { Link } from 'react-router-dom'


export const Footer = () => {
  return (
    <div>
      <div class="pg-footer">
        <footer class="footer">
          <svg
            class="footer-wave-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 100"
            preserveAspectRatio="none"
          >
            <path
              class="footer-wave-path"
              d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
            ></path>
          </svg>
          <div class="footer-content">
            <div class="footer-content-column meni">
              <div class="footer-logo">
                <a class="footer-logo-link" href="#">
                  <img src={Foologo} />
                </a>
                {/* <p>
                    Lorem ipsum dolor sit amet, consect etur adip iscing elit. Proin rhoncus urn a dictum neque molestie ultricies.Lorem ipsum dolor sit amet, consect etur adip iscing elit. Proin rhoncus urn adictum neque molestie ultricies.
                  </p> */}
              </div>
            </div>
            {/* <div class="footer-content-column">
              <div class="footer-menu">
              </div>

            </div> */}
            <div class="footer-content-column meni-1">
              <div class="footer-menu">
                <h2 class="footer-menu-name"> Quick Links</h2>
                {/* <ul id="menu-quick-links" class="footer-menu-list">
                  <li class="menu-item menu-item-type-custom menu-item-object-custom">
                    <a target="_blank" rel="noopener noreferrer" href="#">
                      Support Center
                    </a>
                  </li>
                  <li class="menu-item menu-item-type-custom menu-item-object-custom">
                    <a target="_blank" rel="noopener noreferrer" href="#">
                      Service Status
                    </a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="#">Security</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="#">Blog</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                    <a href="#">Customers</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page">
                    <a href="#">Reviews</a>
                  </li>
                </ul> */}
                <div className="footer-menu-quick-links">
                <Link to={'/about'}>About Us</Link>
    <Link to={'/product'}>Product</Link>
    <Link to={'/quality'}>Quality</Link>
    <Link to={'/contact'}>Contact</Link>
                </div>
              </div>
            </div>
            <div class="footer-content-column meni-2">
              <div class="footer-call-to-action">
                <h2 class="footer-call-to-action-title"> Let's Chat</h2>
                <a className="footer-phone" href="tel:9443895558">+91 94438 95558</a>
                <p class="footer-call-to-action-description">
                  {" "}
                  2/208 RAJIV NAGAR, PERUNDURAI ,Erode, Tamil Nadu-638052

                </p>
              </div>
            </div>
          </div>
          <div class="footer-copyright">
            <div class="footer-copyright-wrapper">
              <p class="footer-copyright-text">
                <a class="footer-copyright-link" href="https://byzerotechnologies.com/" target="_self">
                  {" "}
                  ©2023 All Rights Reserved | Developed by Byzero Technologies {" "}
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
