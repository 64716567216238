import React from "react";
import Bread from "../../Assert/Home/sec-3.png"

export const Section3 = () => {
  return (
    <div>
      <div className="home-section-3">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
                <img className="sec-3-img" src={Bread} alt="" />
            </div>
            <div className="col-md-5 sec-3-home">
                <div className="sec-3-content" data-aos="zoom-in"  data-aos-duration="1000">
                    <h4>Customization and Special Orders</h4>
                    <h3>A Symphony of Flavors, <br /> Straight from the Oven</h3>
                    <p>Looking for something unique for a special occasion? We offer customization and special order services to cater to your specific needs. From personalised cakes and pastries to customised bread assortments, our team will work closely with you to create a one-of-a-kind culinary experience.</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
