import React from "react";
import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";
import img2 from "../Assert/Home/shop-5.jpg";
import "../Css/Qulaity.css"

export const Quality = () => {
  return (
    <div>
      <Navbar />
      <div className="banner">
        <div className="banner-image">
          <div className="container">
              <h3>Quality</h3>
          </div>
        </div>
      </div>
      <div className="abt-sec">
        <p>
          Indulge in the aroma of freshly baked bread and the allure of
          delectable pastries as you step into the world of vel breads. As a
          premier bread and pastries manufacturing company, we take pride in
          bringing the joy of artisanal baking to every home.
        </p>
      </div>
      <div className="quality">
        <div className="container">
          <div className="row">
            <div className="col-md-6 quality2">
              <div className="quality1">
                <p>
                  Prepare to tantalize your taste buds with our wide range of
                  bread and pastries. From crusty artisan bread to delicate
                  French pastries, every item in our collection is a result of
                  meticulous craftsmanship and the finest ingredients. Indulge
                  in our freshly baked baguettes, croissants, muffins, cakes,
                  cookies, and more, each crafted with love and precision.
                </p>
                <p>
                  Whether you're a restaurant owner, café operator, or simply a
                  bread and pastry enthusiast, we cater to both wholesale and
                  retail needs. Our wholesale services provide businesses with
                  the opportunity to serve their customers with our delectable
                  creations, while our retail offerings allow individuals to
                  bring the taste of our bakery into their homes.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="quality1 abt-sec-2">
                <img className="inner-img" src={img2} alt="ASDF" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
