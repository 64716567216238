import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import "bootstrap/dist/css/bootstrap.css";
import Logo from "../Assert/Logo/logo.png";

import { Sling as Hamburger, Sling } from "hamburger-react";

import { Drawer } from "@mui/material";

export const Navbar = () => {
  const [mobileNav, setmobileNav] = useState(false);
  const onclickmenu = () => {
    setmobileNav((prevstate) => !prevstate);
  };
  const closeNav = () => {
    setmobileNav(false);
  };
  return (
    <>
      {/* <div className="mobile-order">
        <ul className="mbl-order-1">
          <li>
            {" "}
            <button> For Oder &nbsp; :</button>
          </li>
          <li>
            {" "}
            <a href="tel:+91 74458 25639">+91 74458 25639</a>
          </li>
        </ul>
      </div> */}
      <div className="nav"  >
        <div className="container" >
          <div className="row navContainer" >
            <div className="col-md-4 mbl-wid">
              <img className="logo" alt="" src={Logo} />
            </div>
            <div className="hamburger" onClick={onclickmenu}>
              <Sling />
            </div>
            <Drawer open={mobileNav} onClose={closeNav} anchor="left">
              <div className={`nav-menu-mobile`}>
                <span className="closemenu" onClick={closeNav}>X</span>

                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to={"/about"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/product"}>Product</Link>
                </li>
                <li>
                  <Link to={"/quality"}>Quality</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contact</Link>
                </li>
              </div>
            </Drawer>
            <div className="col-md-8 nav_menu">
              {/* <div className="row">
                <div className="col-md-12">
                  <div className="header_content">
                    <ul>
                      <li>
                        {" "}
                        <button> For Oder</button>
                      </li>
                      <li>
                        {" "}
                        <a href="tel:+91 74458 25639">+91 74458 25639</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className={`nav_menu-1`}>
                <Link to={"/"}>Home</Link>
                <Link to={"/about"}>About Us</Link>
                <Link to={"/product"}>Product</Link>
                <Link to={"/quality"}>Quality</Link>
                <Link to={"/contact"}>Contact</Link>
              </div>
              {/* {
      mobileNav && (
        <div className={`nav-menu-mobile mobile-menu`}>

      <li><Link to={'/'}>Home</Link></li>
      <li><Link to={'/about'}>About Us</Link></li>
      <li><Link to={'/product'}>Product</Link></li>
      <li><Link to={'/quality'}>Quality</Link></li>
      <li><Link to={'/contact'}>Contact</Link></li>
     </div>
      )
    } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
