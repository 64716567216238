import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slide from "../../Assert/Home/Slider.png";
import Slide2 from "../../Assert/Home/Slider2.png";
import { Carousel } from "react-responsive-carousel";
export const Slider = () => {
  return (
    <div>

<Carousel
        className="home-slider"
        autoplay={true}
        interval={5000}
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
      >
        <div>
          <img src={Slide} alt='slider'/>
        </div>
        <div>
          <img src={Slide2} alt='Slider' />
        </div>
      </Carousel>
    </div>
  )
}
