import React from 'react'
import Form from 'react-bootstrap/Form';
import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';
import  "../Css/Contact.css"

export const Contact = () => {
  return (
    <div>
     <Navbar />
     <div className='banner'>
     <div className='banner-image'>
        <div className='container'>
          <h3>Contact Us</h3>
        </div>
     </div>
     </div>
     <section class="contact_us">
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="contact_inner">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="contact_form_inner">
                                    <div class="contact_field">
                                        <h3>Get In Touch</h3>
                                        <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                                        <input type="text" class="form-control form-group" placeholder="Name" />
                                        <input type="number" class="form-control form-group" placeholder="Mobile" />
                                        <input type="text" class="form-control form-group" placeholder="Email" />
                                        <textarea class="form-control form-group" placeholder="Message"></textarea>
                                        <button class="contact_form_submit">Send</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="right_conatct_social_icon d-flex align-items-end">
                                   <div class="socil_item_inner d-flex">
                                      <li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
                                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact_info_sec">
                            <h4>Contact Info</h4>
                            <div class="d-flex info_single align-items-center">
                                <i class="fas fa-headset"></i>
                                <span><a href='tel:+91 94438 95558'>+91 94438 95558</a></span>
                            </div>
                            <div class="d-flex info_single align-items-center">
                                <i class="fas fa-envelope-open-text"></i>
                                <span><a href='mailto:srivel@gmail.com'>srivel@gmail.com</a></span>
                            </div>
                            <div class="d-flex info_single align-items-center">
                                <i class="fas fa-map-marked-alt"></i>
                                <span>2/208 RAJIV NAGAR, PERUNDURAI ,Erode, Tamil Nadu-638052</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
     </div>
  )
}

